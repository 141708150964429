import React from 'react'
import Layout from '../components/layout'
const Cookies = () => {
    return (
        <Layout>

            <h2>zyppd.in doesn't want to get involved in debates over privacy issues, so we don't collect any unneccesary data. When you make a booking on zyppd.in that data is shared with the shop you made the booking with and is available to certain staff at zyppd.in. This is data that you entered, name, email and phone number, we don't collect your ip address, your location or anything you're not aware of.</h2>
            <p>In this cookie policy you can read about how we use cookies, the types of cookies we use,</p>
            <p>for how long they work and for what purposes they are used, how to change your cookie settings and opt-out, and who are we and how can you contact us.</p>

            <p>When we use words like “us”, “we”, “our” we mean ZYPPD.IN LTD. You can find our company details below. We are the data controller of the data collected through the use of cookies on our website.</p>

            <p>Our website is https://zyppd.in, incl. our sub-domains and other sites we own and operate.</p>
            <p>When we refer to “you” we mean you as a user or visitor of our website.</p>
            <p>This policy is part of our privacy policy. Our use of cookies may include processing of your personal data and we therefore recommend that you read our privacy policy.</p>
            <p>What do I need to know about cookies?</p>
            <p>There are different types of cookies and they are used for different purposes.</p>
            <p>Below you can read about what a cookie is, the difference between first and third party cookies and session cookies vs. persistent cookies and what types of cookies we use on our website and why.</p>

            <p><strong>What is a cookie?</strong></p>
            <p>A cookie is a small piece of data that a website stores on your device when you visit it. A cookie typically contains information about the website itself, a unique identifier that allows the site to recognise your web browser when you return, additional data that serves the purpose of the cookie, and the lifespan of the cookie itself.</p>
            <p>Cookies are used to enable certain features (e.g. logging in), to track site usage (e.g. analytics), to store your user settings (e.g. timezone, notification preferences), and to personalise your content (e.g. advertising, language).</p>

            <p><strong>Session cookies vs. persistent cookies</strong></p>
            <p>Session cookies only last as long as your online session. This means that they will disappear from your computer or device when you close your browser. They are therefore also sometimes referred to as temporary cookies. Typically session cookies are used to remember what a user put in their basket when they are browsing a website.</p>
            <p>Persistent cookies are different. These cookies are sometimes called permanent cookies. They will stay on your computer or device after you close your browser. These types of cookies will expire according to the time specified in the cookie. You can see the specific duration of each persistent cookie below.</p>
            <p>What’s the difference between first and third party cookies?</p>
            <p>First party cookies are cookies that are set by the website that you are visiting and it's only this website that can access and read these cookies.</p>
            <p>Third party cookies are set by someone other than the owner of the website you’re visiting. As an example, some pages have content from other sites like YouTube. YouTube may set their own cookies on your browser when you play the video from YouTube. Cookies set by other sites and companies (ie. third parties) can be used to track you on other websites that use the same third-party service.</p>
            <p>Below you can see what cookies on our website are first party cookies and third party cookies.</p>

            <p><strong>Types of cookies and how we use them</strong></p>

            <p><strong>Necessary cookies</strong></p>
            <p>Necessary cookies are required for the basic functionality of our website to work. We use necessary cookies to make it possible for you to only have to enter your username and password once during a visit to our website.</p>
            <p>We also use necessary cookies to help with ensuring that you are given the option to accept or reject cookies, block non-necessary cookies from working until you give consent, and remember your cookie settings and choices. The cookies also help keep track of, if, and when, you gave consent to analytical cookies, our privacy policy, T&Cs and email marketing.</p>
            <p>Necessary cookies are also used for payment processing.</p>
            <p>It's not possible to reject or accept the necessary cookies as they are core for the functionality of our website.</p>
            <p>Here are the necessary cookies we use, what we use them for, the specific cookies providers and each cookie’s duration:</p>
            <p>Local Storage to enable preserve data related to the options chosen by the user, for example this could be the id associated with a chosen member of staff that is not relevant to the user but is relevant when writing the booking to our database.</p>

            <p><strong>Analytical cookies</strong></p>
            <p>Analytical cookies gather statistics. We use this information to make our website even better. The information collected via the analytical cookies track how you use our website during your visit. It helps us understand visitor usage patterns, identify, and diagnose problems or errors you may encounter, and make better strategic decisions in improving the website experience.</p>
            <p>We will only set analytical cookies on your device if you give us your consent.</p>
            <p>Here are the analytical cookies we use, what we use them for, the specific cookie providers and each cookie’s duration:</p>
            <p>ZYPPD.IN Does not use analytical cookies.</p>
            <p>Our analytics are provided by Google Analytics and as such is covered in the ‘Third-part cookies on our site’ section.</p>

            <p><strong>Marketing cookies</strong></p>
            <p>Marketing cookies are used in determining what promotional content is more relevant and appropriate to you and your interests. Websites may use them to deliver targeted advertising or to limit the number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns, and the quality of content presented to you. These cookies may be set by the website you’re visiting (first-party) or by third-party services. Marketing cookies set by third-parties may be used to track you on other websites that use the same third-party service.</p>
            <p>Here are the Marketing cookies we use, what we use them for, the specific cookie providers and each cookie’s duration:</p>
            <p>ZYPPD.IN LTD does not currently use any marketing cookies.</p>


            <p><strong>Functionality cookies</strong></p>
            <p>Functionality cookies are used in collecting information about your device and any settings you may configure on the website you’re visiting (like language and timezone settings). With this information, websites can provide you with customised, enhanced or optimised content and services.</p>


            <p><strong>Third-party cookies on our site</strong></p>
            <p>We may employ third-party companies and individuals on our websites, e.g. analytics providers and content partners. We grant these third parties access to selected information to perform specific tasks on our behalf.</p>
            <p>Please note that we can’t control what these third party provides do in regards to these cookies.</p>

            <p>How you can change your cookie settings, incl. opting out</p>

            <p>ZYPPD.IN LTD only uses cookies that are strictly necessary or provided by third parties. As such we cannot offer the option to opt out of using cookies.</p>
            <p>Who are we and how can you contact us?</p>

            <p>Here is our company information:</p>
            <p>ZYPPD.IN LTD</p>
            <p>24 Gresham House</p>
            <p>Holborn Viaduct London United Kingdom</p>
            <p>EC1A 2BN</p>

            <p>Company registration number: 12716364</p>

            <p>You can always write to us at: <u>support@zyppd.in</u></p>

            <p>This policy is effective from 07/24/2020</p>
        </Layout>

    )
}

export default Cookies